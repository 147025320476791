$(function () {
  $('a[href*=\\#]:not([href=\\#])').click(function () {
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      var $target = $(this.hash);
      $target =
        ($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        if ($(this).parents('.menuBox').length) {
          setTimeout(function () {
            var targetOffset = $target.offset().top;
            $('html,body').animate({ scrollTop: targetOffset }, 1000);
          }, 100);
        } else {
          var targetOffset = $target.offset().top;
          $('html,body').animate({ scrollTop: targetOffset }, 1000);
        }
        return false;
      }
    }
  });

  // タブ
  const tab = '.tab';
  const label = $(tab).find('.tab-label');
  const content = $(tab).find('.tab-content');
  const tag = label.find('a');
  const current = 'current';
  var state = false;
  var scrollpos;
  //
  showTabContent();
  //
  tag.on('click', function (e) {
    const self = $(this);
    e.preventDefault();
    label.find('li').removeClass(current);
    self.parent().addClass(current);
    showTabContent();
  });
  //
  function showTabContent() {
    const id = $('.' + current)
      .find('a')
      .attr('href');
    content.find('>*').hide();
    $(id).fadeIn();
  }
  // タブ

  // PageTop
  const pagetop = $('#pageTop');
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 100) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  //
  pagetop.on('click', function () {
    $('body, html').animate({ scrollTop: 0 }, 400);
    return false;
  });
  // PageTop

  // アコーディオン
  $('.accordion-item').on('click', function () {
    $(this).find('.accordion-note').slideToggle(180);
    $(this).toggleClass('on');
    $(this).prev('.accordion-item').toggleClass('remove-border');
  });
  // アコーディオン

  // menu
  $('.header-fixed-menu').on('click', function () {
    if (!$(this).hasClass('on')) {
      scrollpos = $(window).scrollTop();
      $('body').addClass('fixed').css({ top: -scrollpos });
      $(
        '.header-fixed-menu,.header-menu,.header-fixed, .header-fixed-bg'
      ).addClass('on');
    } else {
      $('body').removeClass('fixed').css({ top: 0 });
      window.scrollTo(0, scrollpos);
      $(
        '.header-fixed-menu,.header-menu,.header-fixed, .header-fixed-bg'
      ).removeClass('on');
    }
    return false;
  });
  $('.header-fixed-bg').on('click', function () {
    if ($(this).hasClass('on')) {
      $(this).toggleClass('on');
      $('body').removeClass('fixed').css({ top: 0 });
      window.scrollTo(0, scrollpos);
      $('.header-menu, .header-fixed, .header-fixed-menu').toggleClass('on');
    }
  });
  $('.header-menu-linklist-item .arrow,.header-menu-linklist-item > a ').on(
    'click',
    function () {
      const that = $(this).parent();
      if (!that.hasClass('on')) {
        that.addClass('on');
        that.find('.header-menu-inner-linklist').stop().slideDown();
      } else {
        that.removeClass('on');
        that.find('.header-menu-inner-linklist').stop().slideUp();
      }
      return false;
    }
  );
  $('#gNavi .header-navi-item').hover(
    function () {
      if (!$(this).hasClass('on')) {
        $(this).addClass('on');
        $(this).find('.header-navi-inner').stop().fadeIn();
      }
    },
    function () {
      if ($(this).hasClass('on')) {
        $(this).removeClass('on');
        $(this).find('.header-navi-inner').stop().fadeOut();
      }
    }
  );
  // menu

  if ($('.slide').length) {
    $('.slide').each(function () {
      var $this = $(this);
      var num = $this.find('.slide-list-item').length;
      $this.find('.slide-list').slick({
        arrow: true,
        prevArrow: '<div class="slide-prev"></div>',
        nextArrow: '<div class="slide-next"></div>',
      });
      $this.find('.slide-counter .all').text(num);
      $this
        .find('.slide-list')
        .on('afterChange', function (event, slick, currentSlide) {
          $this.find('.slide-counter .now').text(currentSlide + 1);
        });
    });
  }

  if (window.innerWidth < 980) {
    $('.footer-list-link-item .arrow,.footer-list-link-item > a').on(
      'click',
      function () {
        const that = $(this).parent();
        if (!that.hasClass('on')) {
          that.addClass('on');
          that.find('.footer-list-link-innerlist').stop().slideDown();
        } else {
          that.removeClass('on');
          that.find('.footer-list-link-innerlist').stop().slideUp();
        }
        return false;
      }
    );
  }

  if ($('.table-scroll-wrapper').length) {
    $('.table-scroll-wrapper').scroll(function () {
      var left = $(this).scrollLeft();
      if (left > 0) {
        $('.table-scroll-wrapper').addClass('scroll');
      }
    });
  }
  var bigfonts = $.cookie('bigfonts');
  if (bigfonts == '1') {
    $('.header-inner .size-change ul li')
      .removeClass('on')
      .eq(0)
      .addClass('on');
    $('html').css('font-size', 'calc( 1.2em * 0.625)');
  } else {
    $('.header-inner .size-change ul li')
      .removeClass('on')
      .eq(1)
      .addClass('on');
    $('html').css('font-size', 'calc( 1em * 0.625)');
  }
  $('.header-inner .size-change ul li a').on('click', function () {
    if ($(this).text() == '拡大') {
      $('.header-inner .size-change ul li')
        .removeClass('on')
        .eq(0)
        .addClass('on');
      $('html').css('font-size', 'calc( 1.2em * 0.625)');
      $.cookie('bigfonts', '1');
    } else {
      $('.header-inner .size-change ul li')
        .removeClass('on')
        .eq(1)
        .addClass('on');
      $('html').css('font-size', 'calc( 1em * 0.625)');
      $.cookie('bigfonts', '0');
    }
  });

  // ナビゲーション アコーディオン
  $('.sub-menu-name').on('click', function (e) {
    e.preventDefault();
    $(this).next('.sub-menu-list').slideToggle(180);
    $(this).toggleClass('on');
  });
  if ($('.sub-menu-list-item.on')) {
    var subMenuList = $('.sub-menu-list-item.on').parent('.sub-menu-list');
    subMenuList.slideDown(180);
    subMenuList.prev('.sub-menu-name').addClass('on');
  }

  // Youtube モーダル
  $('.js-modal-btn').modalVideo({
    channel: 'custom',
    url: '/videos/recruit/reha/apprentice/meeting_promotion.mp4',
  });
});
